































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import User from '@/models/User';
import UserRole from '@/models/user-attributes/UserRole';
import {namespace} from 'vuex-class';
import {locationStoreActions} from '@/stores/location.store';
import {customerStoreActions} from '@/stores/customer.store';
import {userStoreActions} from '@/stores/user.store';

const CustomerStore = namespace('customer');
const UserStore = namespace('user');
const LocationStore = namespace('location');

interface Filter {
  locations: 'all' | 'selected' | 'none';
  customers: 'all' | 'selected' | 'none';
  users: 'all' | 'selected' | 'none';
}

@Component({
  components: {
    UserInitialsComponent: () => import(
        /* webpackChunkName: "UserInitialsComponent" */
        '@/components/user/UserInitials.component.vue'),
    FilterRadioComponent: () => import(
        '@/components/shared/FilterRadio.component.vue'),
    RJAutocomplete: () => import(
        '@/components/shared/custom-vuetify/RJAutocomplete.vue'),
  },
})
export default class MapFilterComponent extends Vue {

  @Prop({default: true})
  public autofocus!: boolean;

  public selectedCustomers: Customer[] = [];
  public selectedUsers: User[] = [];
  public selectedManager: User[] = [];
  public selectedLocations: Location[] = [];

  public markerObjects: any = {
    customer: [],
    location: [],
    user: [],
  };

  /**
   * Bool to filter locations according to the selected managers
   * @private
   */
  private filterLocations: 'all' | 'selected' | 'none' = 'all';
  /**
   * Bool to show the items on the map
   * @private
   */
  private show: Filter = {
    users: 'all',
    customers: 'all',
    locations: 'all',
  };

  /**
   * All loaded locations of the active customers of the active tenant
   * @private
   */
  private loadedLocations: Location[] = [];

  @CustomerStore.Action(customerStoreActions.LOAD_CUSTOMERS_ACTION)
  private loadCustomersAction!: (tenantId: string) => Promise<Customer[]>;
  @UserStore.Action(userStoreActions.LOAD_USERS_ACTION)
  private loadUsersAction!: (payload: { tenantId: string, options?: any }) => Promise<User[]>;
  @CustomerStore.Action(customerStoreActions.LOAD_CUSTOMERS_ACTION)
  private loadCustomerAction!: (customerId: string) => Promise<Customer>;
  @LocationStore.Action(locationStoreActions.LOAD_LOCATIONS_ACTION)
  private loadLocationsAction!: (payload: {tenantId: string, relations?: string[], store: boolean} ) => Promise<Location[]>;

  @UserStore.Getter('users')
  private _users!: User[];
  @CustomerStore.Getter('customers')
  private _customers!: Customer[];

  /**
   * Get all active users with the role 'manager' or 'objectManager'
   */
  public get manager(): User[] {
    return this._users;
  }

  /**
   * Get all active users with the role 'employee'. Filter optionally for having a geoPosition
   * @param filter
   */
  public users(filter?: boolean): User[] {
    return this._users;
  }

  /**
   * Get all customers. Optionally filter for having a geoPosition
   */
  public get customers(): Customer[] {
    return this._customers;
  }

  /**
   * Filters the location according to the selected managers. Optionally filter for having a geoPosition
   * @param filterForAddress
   */
  public filteredLocations(filterForAddress?: boolean): Location[] {
    return this.locations(filterForAddress);
  }

  /**
   * Get all locations from the loaded customers. Can filter for having a geoPosition
   */
  public locations(filter?: boolean): Location[] {
    const locations: Location[] = [];
    for (const customer of (this.selectedCustomers.length > 0 ? this.selectedCustomers : this.customers)) {
      for (const location of this.loadedLocations) {
        if (!location.address?.id) {
          location.address = customer.address;
        }
        // If the locations' customer does not match, or it has no address or geoPosition, don't push
        if ((location.customer as Customer).id === customer.id) {
          if (filter && !(location.address && location.address?.geoPosition)) {
            continue;
          }
          locations.push(location);
        }
      }
    }
    return locations;
  }

  public async mounted() {
    try {
      // Load the customers
      await this.loadCustomersAction(this.$route.params.tenantId);
      // Load the users
      const params = {tenantId: this.$route.params.tenantId};
      await this.loadUsersAction(params);
      // Load the locations
      this.loadedLocations = await this.loadLocationsAction({tenantId: params.tenantId, relations: ['managers'], store: false});
      this.updateMarkerObjects();
      this.triggerCentralization();
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  public getUserFullName(user: User) {
    return user.fullName;
  }

  public updateMarkerObjects() {
    // Make an array of the objects, that should be displayed on the map
    const markerObjects = [
      ...(this.show.customers !== 'none' ?
          (this.show.customers === 'selected' ? this.selectedCustomers : this.customers) :
          []),
      ...(this.show.locations !== 'none' ?
          (this.show.locations === 'selected' ? this.selectedLocations : this.filteredLocations()) :
          []),
      ...(this.show.users !== 'none' ?
          (this.show.users === 'selected' ? this.selectedUsers : this.users()) :
          [])];
    this.$emit('new-marker-objects', markerObjects);
  }

  /**
   * Method to delete item from filter
   */
  public onRemoveFromFilter(index: number, collection: any[]): void {
    collection.splice(index, 1);
    this.updateMarkerObjects();
  }

  private triggerCentralization() {
    this.$emit('centralize');
  }

  private onReloadClicked() {
    this.selectedManager = [];
    this.selectedUsers = [];
    this.selectedCustomers = [];
    this.selectedLocations = [];
    this.updateMarkerObjects();
  }
}
